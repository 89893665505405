
import {Link, useLocation} from "react-router-dom";
import {Layout, Menu} from "antd"
import {HomeOutlined, UserOutlined, PaperClipOutlined, CarOutlined} from "@ant-design/icons"

import "./MenuSider.scss";



export default function MenuSider(props){
    
    const {menuCollapsed} = props;
    const {Sider} = Layout;
    const {pathname} = useLocation(); 

    return (
        <Sider className="admin-sider" collapsed={menuCollapsed}>
            <Menu 
                theme="dark" 
                mode="inline"
                defaultSelectedKeys={pathname}>
                <Menu.Item key="/admin">
                    <Link to={"/admin"}>
                         <HomeOutlined />
                         <span className="nav-text">Home</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/vehicles">
                    <Link to={"/vehicles"}>
                         <CarOutlined />
                         <span className="nav-text">Vehiculos Pedidos</span>
                    </Link>
                </Menu.Item>
                {/* <Menu.SubMenu key="SubOrders" title={
                            <>
                                <PaperClipOutlined />
                                <span>Pedidos</span>
                            </>}>
                    <Menu.Item key="/orders">
                        <Link to={"/orders"}>
                             <span className="nav-text">Pedidos</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/orders/add">
                        <Link to={"/orders/add"}>
                             <span className="nav-text">Nuevo</span>
                        </Link>
                    </Menu.Item>
                    
                </Menu.SubMenu>
                <Menu.Item key="/admin/users">
                    <Link to={"/admin/users"}>
                        <UserOutlined />
                         <span className="nav-text">Usuarios</span>
                    </Link>
                </Menu.Item> */}
            </Menu>

        </Sider>
    );
}