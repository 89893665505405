import { Timeline, Card } from "antd";
import moment from "moment";

export default function Indicators(props){
    const {vehicles} = props;


    const uniqueDates = [];
    vehicles.map((item) => {
        if(item.history==null || item.length == 0)
                    item.status = null;
                else {
                    item.status = item.history[item.history.length-1].status;
                    item.lastDate = item.history[item.history.length-1].newDate;
                }
        var findItem = uniqueDates.find((x) => x === item.lastDate);
        if (!findItem) uniqueDates.push(item.lastDate);
        
    });

    uniqueDates.sort((a, b) => moment(a, "DD/MM/YYYY") - moment(b, "DD/MM/YYYY"));

    return <div className="indicators">
        <h2>Próximos Arrivos</h2>
        <Card>
            <Timeline>
                {uniqueDates.map((item) => {
                    return <Timeline.Item color={moment(item, "DD/MM/YYYY") < moment() ? "red" : "blue"}>
                        <h3>{item}</h3>
                        <VehiclesDate vehicles={vehicles} date={item}></VehiclesDate>
                    </Timeline.Item>      
                })}
            </Timeline>
        </Card>
    </div>
}

function VehiclesDate(props){
    const {vehicles, date} = props;

    const vehiclesDate = [];
    vehicles.map((item) => {
        if(date === item.lastDate){ 

            const desc = `${item.type} ${item.transmission} ${item.fuelType}  ${item.hp}hp ${item.pass}`;

            var findItem = vehiclesDate.find((x) => x.vehicle === desc);
            if(!findItem)
                vehiclesDate.push({"vehicle": desc, "cant": 1});
            else
                findItem.cant = findItem.cant + 1;
        }
    })

    vehiclesDate.sort((a, b) => a.cant - b.cant);

    return <div>{vehiclesDate.map((item) => {
                
                    return <p>{item.cant} {item.vehicle}</p>
                 
            })
        }
    </div>
}