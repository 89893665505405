import { Table, Tag, Space } from 'antd';


import "./ListOrders.scss"

export default function ListOrders(props){

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            
        },
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "No. Vehiculos",
            dataIndex: "cantVehicles",
            key: "cantVehicles"
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: status => {
                let color;
                switch(status){
                    case "Cancelado":
                        color = "volcano";
                        break;
                    case "Proceso": 
                        color = "green"
                        break;
                    case "Captura":
                        color = "geekblue";
                        break;
                    default:
                        color = "geekblue"
                }
                return (
                  <Tag color={color} key={status}>
                    {status.toUpperCase()}
                  </Tag>)
            }

        },
        {
            title: "Accion",
            dataIndex: "status",
            key: "action",
            render: status => {
                if(status === "Captura"){
                    return <a>Autorizar</a>;
                }else if (status === "Proceso"){
                    return <a>Cancelar</a>
                }
            }
        }
        

    ]

    const data = [
        {
            id:1,
            date:"09/10/2021",
            cantVehicles: 8,
            status: "Proceso"
        },
        {
            id:2,
            date:"10/02/2022",
            cantVehicles: 5,
            status: "Captura"
        },
        {
            id:3,
            date:"14/04/2022",
            cantVehicles: 10,
            status: "Cancelado"
        },
    ]

    return(
        <div className="list-orders">
            <Table columns={columns} dataSource={data}></Table>

        </div>
    )

}