import { Avatar as AvatarAntd} from "antd";
import { useState, useEffect } from "react";
import { getAvatarApi } from "../../../../api/user";
import NoAvatar from "../../../../assets/img/no-avatar.png"



export default function Avatar(props){
    const {user, size} = props;
    const [avatar, setAvatar] = useState(null);
    
    useEffect(()=>{
        if(user.avatar){
            getAvatarApi(user.avatar).then(response => {
                setAvatar(response)
            });
        }else{
            setAvatar(null);
        }
    }, [user]);

    const styleObj = {fontSize: size?`${size/2}px`: null}

    return (
        user.avatar?
            <AvatarAntd 
            size={size}
            src={avatar}/>:
            (!user.email)?
                <AvatarAntd 
                size={size}
                src={NoAvatar}/>
                :
                <AvatarAntd   size={size} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    <div style={styleObj}>{
                    !(user.name && user.lastName)?
                        user.email.charAt(0).toUpperCase():
                        user.name.charAt(0).toUpperCase()+
                        user.lastName.charAt(0).toUpperCase()
                        }</div>
                    
                </AvatarAntd>
    );
}