import { useState } from "react";
import {Table, Button, Modal as ModalAntd, notification} from "antd";
import Modal from "../../Modal";
import {HistoryOutlined} from "@ant-design/icons";
import AddVehicleForm from "../AddVehicleForm";
import History from "../History";
import moment from "moment";

import "./ListVehicles.scss"
import { deleteVehicleApi } from "../../../api/vehicles";
import { getAccessTokenApi } from "../../../api/auth";

const {confirm} = ModalAntd;
export default function ListVehicles(props){
    const {vehicles, setReloadVehicles} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    
    const uniqueStatus = [];
    const uniqueClients = [];
    const uniqueTipo = [];
    const uniqueVin = [];

    vehicles.map((item) => {
        if(item.history==null || item.length == 0)
                    item.status = null;
                else {
                    item.status = item.history[item.history.length-1].status;
                    item.lastDate = item.history[item.history.length-1].newDate;
                }

        var findItem = uniqueStatus.find((x) => x.value === item.status);
        if (!findItem) uniqueStatus.push({"text": item.status, "value" : item.status});

        var findCliente = uniqueClients.find((x) => x.value === item.cliente);
        if (!findCliente) uniqueClients.push({"text": item.cliente, "value" : item.cliente});

        var findTipo = uniqueTipo.find((x) => x.value === item.type);
        if (!findTipo) uniqueTipo.push({"text": item.type, "value" : item.type});
        
        var findVin = uniqueVin.find((x) => x.value === item.vinMex);
        if (!findVin) uniqueVin.push({"text": item.vinMex, "value" : item.vinMex});


    });

    uniqueClients.sort(function (a,b) { 
        let fa = a.value.toLowerCase(),
        fb = b.value.toLowerCase();

        if (fa < fb) return -1;
        if (fa > fb) return 1;
        return 0;

    });
    uniqueTipo.sort(function (a,b) { 
        let fa = a.value.toLowerCase(),
        fb = b.value.toLowerCase();

        if (fa < fb) return -1;
        if (fa > fb) return 1;
        return 0;

    });



    const colTab = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 20,
        },
        {
            title: "Vin",
            dataIndex: "vinMex",
            key: "vinMex",
            width: 61,
            filters: uniqueVin,
            filterSearch: true,
            onFilter: (value, record) => record.vinMex.indexOf(value) === 0,
        },
        {
            title: "Model",
            dataIndex: "type",
            key: "type",
            width: 90,
            filters: uniqueTipo,
            filterSearch: true,
            onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
            title: "Shin",
            dataIndex: "made",
            key: "made",
            width: 28
        },
        {
            title: "Paso",
            dataIndex: "pass",
            key: "pass",
            width: 28
        },
       
        {
            title: "Cliente",
            dataIndex: "cliente",
            key: "cliente",
            filters: uniqueClients,
            filterSearch: true,
            width: 50,
            onFilter: (value, record) => record.cliente.indexOf(value) === 0,
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            //fixed: 'right',
            filters: uniqueStatus,
            filterSearch: true,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            width: 50,
            //filterSearch : true,
            
        },
        {
            title: "Fecha de llegada",
            dataIndex: "lastDate",
            key: "lastDate",
            width: 40,
            //fixed: 'right',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.lastDate, "DD/MM/YYYY") - moment(b.lastDate, "DD/MM/YYYY"),
        },
        {
            width: 24,
            dataIndex: "_id",
            key: "delete",
            fixed: 'right',
            render: (id, record) => {
                // return <><Button 
                //             type="danger"
                //             onClick={() => deleteVehicle(id)}
                //             >
                //                 <DeleteOutlined />
                //         </Button>
                return <Button 
                            type="primary"
                            onClick={() => historyModal(id, record.history)}
                            >
                                <HistoryOutlined  />
                        </Button>
                    //  </>
            }
        },
    ]

    const deleteVehicle = (id) => {

        confirm(
            {
                title: "Eliminar vehiculo",
                content: "¿Estás seguro de eliminar el vehiculo",
                okText: "Si, Eliminar",
                okType: "danger",
                cancelText: "No",
                onOk(){
                    const token = getAccessTokenApi();
                    deleteVehicleApi(token, id).then(result => {
                        if(result.error){
                            notification["error"]({message: result.error});    
                        }else{
                            notification["success"]({message: "Vehiculo Eliminado correctamente"});
                            setReloadVehicles(true);
                        }});
                }
            }
        )

        console.log(id);
    }

    const editVehicle = (id) => {
        console.log("Editar vehiculo");
    }

    const historyModal = (id, history) => {
        setIsModalVisible(true);
        setModalTitle("Historial");
        setModalContent(<History history={history} id={id} setReloadVehicles={setReloadVehicles} setIsModalVisible={setIsModalVisible} ></History>)
    }

    const addVehicleModal = () => {
        console.log("Agregar vehiculo");
        setIsModalVisible(true);
        setModalTitle("Nuevo vehículo");
        setModalContent(<AddVehicleForm setReloadVehicles={setReloadVehicles} setIsModalVisible={setIsModalVisible}></AddVehicleForm>);
    }
    
    return(
        <div className="list-vehicles">
            {/* <div className="list-vehicles__header">
            <Button type="primary" onClick={addVehicleModal} >
                Agregar Vehículo
            </Button>
            </div> */}
            <div className="list-vehicles__table">
                <Table columns={colTab} dataSource={vehicles}
                    scroll={{ x: 25 }}
                    ></Table>
            </div>
            <Modal
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                width={850}
            >
                {modalContent}
            </Modal>
        </div>
    );
}