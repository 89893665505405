import { basePath, apiVersion } from "./config";

export function getVehiclesApi(token, query){
    const url = `${basePath}/${apiVersion}/vehicle-get?${query}`;

    const  params = {
        method: "GET",
        headers:{
            "Content-Type": "application/json",
            Authorization: token,
        }
    };

    return fetch (url, params)
        .then(response => {
            return response.json();
        }).then(result => {
            return result;
        }).catch(err => {
            return err.message;
        });    

}

export function addVehicleApi(token, data){
    const url = `${basePath}/${apiVersion}/vehicle-add`;
    const  params = {
        method: "POST",
        body: JSON.stringify(data),
        headers:{
            "Content-Type": "application/json",
            Authorization: token,
        }   
    };

    return fetch (url, params)
        .then(response => {
            if(!response.ok) {
                return response.json().then(text => { throw new Error(text.message) })
            } else { 
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
            return {"error": err.message};
        });   
}

export function deleteVehicleApi(token, id){
    const url = `${basePath}/${apiVersion}/vehicle-delete/${id}`;
    const  params = {
        method: "DELETE",
        headers:{
            "Content-Type": "application/json",
            Authorization: token,
        }   
    };
    return fetch (url, params)
        .then(response => {
            if(!response.ok) {
                return response.json().then(text => { throw new Error(text.message) })
            } else { 
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
            return {"error": err.message};
        });   

}

export function updateHistoryApi(token, id, history){
    const url = `${basePath}/${apiVersion}/vehicle-add-history/${id}`;
    const params = {
        method : "PUT",
        headers:{
            "Content-Type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify({
            "history": history
        }),
    }
    console.log(params);
    return fetch (url, params)
        .then(response => {
            return response.json();
        }).then(result => {
            return result.message;
        }).catch(err => {
            return err.message;
        });    
}