import {Avatar as AvatarAntd, Form, Input, Select, Button, Row, Col, notification} from "antd"
import Avatar from "../Avatar";
import {useDropzone} from "react-dropzone"
import { useState, useCallback, useEffect} from "react";
import NoAvatar from "../../../../assets/img/no-avatar.png"
import {UserOutlined, MailOutlined, LockOutlined} from "@ant-design/icons";
import { addUserApi, getAvatarApi} from "../../../../api/user";
import {getAccessTokenApi} from "../../../../api/auth"

import "./AddUserForm.scss";


export default function AddUserForm(props){
    const {user, } = props;
    const [avatar, setAvatar] = useState(null);
    const [userData, setUserData] = useState({
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        avatar: user.avatar,
        role: user.role
    });

    useEffect(() => {
        setUserData({
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            avatar: user.avatar,
            role: user.role
        })
    }, [user]);

    useEffect(() => {
        if(user.avatar){
            getAvatarApi(user.avatar).then(response => {
                setAvatar(response);
            })
        } else {
            setAvatar(null);
        }
    },  [user])

    useEffect(() => {
        if(avatar){
            setUserData({...userData, avatar: avatar.file});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[avatar]);

    const addUser = e => {
        e.preventDefault();
        const token = getAccessTokenApi();
        let userToAdd = userData;

        

        if(!userToAdd.name || 
            !userToAdd.lastName || 
            !userToAdd.email || 
            !userToAdd.role ||
            !userToAdd.password){
            notification["error"]({message: "Todos los datos son obligatorios"});
            return;
        }

        if(userToAdd.password || userToAdd.repeatPassword ){
            if(userToAdd.password !== userToAdd.repeatPassword){
                notification["error"]({
                    message: "Las contraseñas no coinciden"
                });
                return;
            } 
        }
        addUserApi(token, userToAdd).then(result => {
            console.log(result);
            if(result.error){
                notification["error"]({message: result.error});    
            }else{
                notification["success"]({message: result.message});
            } 
            //setIsModalVisible(false);
            //setReloadUsers(true);
        });

        // if(typeof userToAdd.avatar === "object"){
        //     uploadAvatarApi(token, userToAdd.avatar, user._id).then(response => {
        //         userToAdd.avatar = response.avatarName;
        //         addUserApi(token, userToAdd).then(result => {
        //             notification["success"]({message: "Usuario actualizado"});
        //             setIsModalVisible(false);
        //             setReloadUsers(true);
        //         }).catch(e => {
        //             console.log(e.message);
        //         })
        //     });
        // }else{
            
        // }


    }

    return (
        <div className="edit-user-form">
            <UploadAvatar user={user} avatar={avatar} setAvatar={setAvatar}></UploadAvatar>
            <AddForm 
                userData={userData}
                setUserData={setUserData}
                updateUser={addUser}
            ></AddForm>
        </div>
    );
}

function UploadAvatar(props){
    const {avatar, setAvatar, user} = props;
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        if(avatar){
            if(avatar.preview){
                setAvatarUrl(avatar.preview);
            } else {
                setAvatarUrl(avatar);
            }
        } else{
            setAvatarUrl(null);
        }
    }, [avatar])

    const onDrop = useCallback(
        acceptedFiles =>{
            const file = acceptedFiles[0];
            setAvatar({file, preview: URL.createObjectURL(file)});
        }, [setAvatar]
    );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: "image/jpeg, image/jpg, image/png",
        noKeyboard: true,
        onDrop
    });

    return(
        <div className="upload-avatar" {...getRootProps()}>
            <input {...getInputProps()}></input>
            {isDragActive? 
                (<AvatarAntd size={150} src={NoAvatar} />):
                (
                    avatar?
                        <AvatarAntd size={150} src={avatarUrl? avatarUrl : NoAvatar} />:
                        <Avatar user={user} size={150}></Avatar>
                )
            }
        </div>
    );
}

function AddForm(props){
    const {userData, setUserData, updateUser} = props;
    const {Option} = Select;



    return (
        <Form className="form-edit" onSubmitCapture={updateUser}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item>
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Nombre"
                            value={userData.name} 
                            onChange={e => setUserData({...userData, name: e.target.value})}
                            />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Apellidos"
                            value={userData.lastName} 
                            onChange={e => setUserData({...userData, lastName: e.target.value})}
                            />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}><Form.Item>
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Correo electrónico"
                            value={userData.email} 
                            onChange={e => setUserData({...userData, email: e.target.value})}
                            />
                    </Form.Item></Col>
                <Col span={12}>
                    <Form.Item>
                        <Select placeholder="Selecciona un rol"
                            onChange={e => setUserData({...userData, role: e})}
                            value={userData.role}
                        >
                            <Option value="lectura">Lectura</Option>
                            <Option value="pedidos">Pedidos</Option>
                            <Option value="autoriza">Autorizador</Option>
                            <Option value="admin">Administrador</Option>

                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item>
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Contraseña"
                            onChange={e => setUserData({...userData, password: e.target.value})}
                            />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Repetir Contraseña"
                            onChange={e => setUserData({...userData, repeatPassword: e.target.value})}
                            />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submmit" className="btn-submit"> 
                    Agregar Usuario
                </Button>
            </Form.Item>
        </Form>
        )
}