import { useState, useEffect} from "react";
import Indicators from "../../components/Admin/Indicators/Indicators";
import { getAccessTokenApi } from "../../api/auth";
import { getVehiclesApi } from "../../api/vehicles";

export default function Admin() {
    const [vehicles, setVehicles] = useState([]);

    const token = getAccessTokenApi();

    useEffect(() => {
        getVehiclesApi(token, {}).then(res => {
            setVehicles(res.vehicles);
        });
    }, [token]);


    return <Indicators vehicles={vehicles}></Indicators>;
}