import "./SignIn.scss";
import { Layout, Tabs } from "antd";
import Logo from "../../../assets/img/logo-vetsa-blanco.png"
import RegisterForm from "../../../components/Admin/RegisterForm";
import LoginForm from "../../../components/Admin/LoginForm/LoginForm";

export default function SignIn(){

    const {Content} = Layout;
    const {TabPane} = Tabs;


    return (
        <Layout className="sign-in">
            <Content className="sign-in__content">
                <h1 className="sign-in__content-logo">
                    <img src={Logo} alt="logo"></img>
                </h1>
                <div className="sign-in__content-tabs">
                    <Tabs type="card" defaultActiveKey="1" >
                        <TabPane tab={<span>Entrar</span>} key="1">
                            <LoginForm></LoginForm>
                        </TabPane>
                        <TabPane tab={<span>Nuevo Usuario</span>} key="2">
                            <RegisterForm></RegisterForm>
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
        </Layout>
    )
}