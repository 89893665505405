import { Switch, List, Button, notification, Modal as ModalAntd } from "antd";
import { useState } from "react";
import Modal from "../../../Modal";
import {EditOutlined, StopOutlined, DeleteOutlined, CheckSquareOutlined} from "@ant-design/icons";
import EditUserForm from "../EditUserForm";
import Avatar from "../Avatar";
import {activateUserApi, deleteUserApi} from "../../../../api/user"
import {getAccessTokenApi} from "../../../../api/auth"
import AddUserForm from "../AddUserForm/AddUserForm";


import "./ListUsers.scss";
const {confirm} = ModalAntd;

export default function ListUsers(props){
    const {usersActive, usersInactive, setReloadUsers} = props; 
    const [viewUsersActives, setViewUsersActives] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    
    const addUserModal = () => {
        const user = {
            _id: null,
            name: null,
            lastName: null,
            email: null,
            avatar: null,
        } 
        setIsModalVisible(true);
        setModalTitle("Nuevo Usuario");
        setModalContent(<AddUserForm user={user} setIsModalVisible={setIsModalVisible} setReloadUsers={setReloadUsers}></AddUserForm>)

    }

    return (
        <div className="list-users">
            <div className="list-users__header">
                <div className="list-users__header-switch">
                    <Switch
                        defaultChecked
                        onChange={() => setViewUsersActives(!viewUsersActives)}
                        />
                        <span>
                            {viewUsersActives? "Usuarios Activos": "Usuarios Inactivos"}
                        </span>
                </div>
                <Button type="primary" onClick={addUserModal}>
                    Nuevo Usuario
                </Button>
            </div>
            {viewUsersActives? 
                <UsersActive 
                    users={usersActive} 
                    setModalContent={setModalContent} 
                    setIsModalVisible={setIsModalVisible}
                    setModalTitle={setModalTitle}
                    setReloadUsers={setReloadUsers}
                    /> : 
                <UsersInactive 
                    users={usersInactive}
                    setReloadUsers={setReloadUsers}
                />}

            <Modal
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
            >
                {modalContent}
            </Modal>

        </div>
        );

}

function UsersActive(props){
    const {users, setIsModalVisible, setModalContent, setModalTitle, setReloadUsers} = props;
    const token = getAccessTokenApi();

    const editUser = user => {
        setIsModalVisible(true);
        setModalTitle(`Editar ${user.name?? "..."} ${user.lastName??"..."}`)
        setModalContent(<EditUserForm user={user} setIsModalVisible={setIsModalVisible} setReloadUsers={setReloadUsers}></EditUserForm>)
    }

    const inactivateUser = (user) => {
        activateUserApi(token, false, user._id);
        notification["success"]({message: "Usuario desactivado correctamente"});
        setReloadUsers(true)
    }


    return (
        <List 
            className="users-active"
            itemLayout="hotizontal"
            dataSource={users}
            renderItem={user => (
                <List.Item
                    actions={[
                        <Button 
                            type="primary"
                            onClick={() => editUser(user)}
                            >
                                <EditOutlined />
                            </Button>,
                        <Button 
                            type="danger"
                            onClick={() => inactivateUser(user)}
                            >
                                <StopOutlined />
                         </Button>,
                    ]}
                >
                    <List.Item.Meta 
                        avatar={<Avatar user={user}></Avatar>}
                        title={`
                            ${user.name? user.name: "..."}
                            ${user.lastName? user.lastName : "..."}
                        `}
                        description={user.email}
                    />
                    <RoleDesc role={user.role} />
                </List.Item>
            )}
        />
    );
}

function UsersInactive(props){
    const {users, setReloadUsers} = props;
    

    const inactivateUser = (user) => {
        const token = getAccessTokenApi();
        activateUserApi(token, true, user._id);
        notification["success"]({message: "Usuario activado correctamente"});
        setReloadUsers(true)
    }



    return (
        <List 
            className="users-active"
            itemLayout="hotizontal"
            dataSource={users}
            renderItem={user => (
                <List.Item
                    actions={[
                        <Button 
                            alt="Activar Usuario"
                            type="primary"
                            onClick={() => inactivateUser(user)}
                            >
                                <CheckSquareOutlined />
                            </Button>,
                         <DeleteButton
                            user={user}
                            setReloadUsers={setReloadUsers}
                        />
                    ]}
                >
                    <List.Item.Meta 
                        avatar={<Avatar user={user}></Avatar>}
                        title={`
                            ${user.name? user.name: "..."}
                            ${user.lastName? user.lastName : "..."}
                        `}
                        description={user.email}
                    />
                </List.Item>
            )}
        />
    );
}

function DeleteButton(props){
    const {user, setReloadUsers} = props;

    const showDeleteConfirm = () => {
        const token = getAccessTokenApi();
        confirm({
            title: "Eliminar Usuario",
            content: `¿Estás seguro que quieres eliminar a ${user.email}?`,
            okText: "Si, Eliminar",
            okType: "danger",
            cancelText: "No",
            onOk(){
                deleteUserApi(token, user._id)
                    .then(response => {
                            notification["success"]({message: response});
                    })
                    .catch(err => {
                        notification["error"]({message: err});
                    });
                
                setReloadUsers(true);
            }
        })

    }

    return(
    <Button 
        type="danger"
        onClick={showDeleteConfirm}
        >
            <DeleteOutlined />
    </Button>
    );
}

function RoleDesc(props){
    const {role} = props;

    const roles=[
        {
            role: 'lectura',
            desc: 'Lectura'
        },
        {
            role: 'pedidos',
            desc: 'Pedidos'
        },
        {
            role: 'admin',
            desc: 'Administrador'
        },
        {
            role: 'autoriza',
            desc: 'Autorizador'
        },
    ]
    const roleDesc = roles.find((element)=>{
        if (element.role === role)
            return element;
        else return "";
    });

    return (<h3>
        {roleDesc.desc}
    </h3>);
}