
import "./App.scss"
import {Route,  Routes, HashRouter} from "react-router-dom";
import routes from "./config/routes";
import AuthProvider from "./providers/AuthProvider";

function App() {

  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          {
            routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.layout>
                  <route.component />
                </route.layout>}
              />
            ))
          }
        </Routes>
      </HashRouter>
    </AuthProvider>
    
    
  );
} 


/* RUTAS...
function App() {
  return (
    <Router>
      <div className="app">
        <h1>Sistema de Rutas Básico</h1>

        <Link to="/">Home</Link><br/>
        <Link to="/contact">Contacto</Link><br/>
        <Link to="/users">Usuarios</Link><br/><br/>
        

        <Routes>
          <Route path="/"  element={<Home/>} />
          <Route path="/contact"  element={<Contact/>} />
          <Route path="/users"  element={<Users/>} />
          <Route path="*"  element={<Error404/>} />
        </Routes>
      </div>
    </Router>
  );
}

function Home(){
  return <h2>Estamos en el componente home</h2>
}
function Contact(){
  return <h2>Componente Contact</h2>
}
function Users(){
  return <h2>Estamos en Users</h2>
}
function Error404(){
  return <h2>Error404</h2>
}
*/

export default App;
