//Layouts
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

//AdminPages
import AdminHome from "../pages/Admin";
import AdminUsers from "../pages/Admin/Users"

import Orders from "../pages/Admin/Orders"
import Vehicles from "../pages/Admin/Vehicles"
import AddOrder from "../pages/Admin/Orders/AddOrder";

//ClientePages
import Contact from "../pages/Contact";
import Home from "../pages/Home";

//Others
import Error404 from "../pages/Error404"

const routesAdmin = [
    {   
        path: "/admin",
        layout: LayoutAdmin,
        component: AdminHome,
    },
    {   
        path: "/admin/users",
        layout: LayoutAdmin,
        component: AdminUsers,
    },
    {   
        path: "/orders",
        layout: LayoutAdmin,
        component: Orders,
    },
    {   
        path: "/orders/add",
        layout: LayoutAdmin,
        component: AddOrder,
    },
    {   
        path: "/vehicles",
        layout: LayoutAdmin,
        component: Vehicles,
    },
    {   
        path: "admin/*",
        layout: LayoutAdmin,
        component: Error404,
    }
]

const routesCliente = [
    {   
        path: "/",
        layout: LayoutBasic,
        component: Home,
    },
    {   
        path: "/contact",
        layout: LayoutBasic,
        component: Contact,
    },
    {   
        path: "*",
        layout: LayoutBasic,
        component: Error404,
    },
];

const routes = [...routesAdmin, ...routesCliente];

export default routes