
import { useState } from "react";
import {Table, Form, Row, Col, Input, Button} from "antd"
import { getAccessTokenApi } from "../../../api/auth";
import moment from "moment";



import "./History.scss"
import { updateHistoryApi } from "../../../api/vehicles";

export default function History(props){
    const {history, id, setIsModalVisible, setReloadVehicles} = props;

    const[newHistory, setNewHistory] = useState({});

    const updateHistory = e => {
        e.preventDefault();
        const token = getAccessTokenApi();
        setNewHistory({...newHistory, date: moment().format('DD/mm/yyyy')})

        const historyToAdd = [...history, newHistory];

       
           

        updateHistoryApi(token, id, historyToAdd);
        setIsModalVisible(false);
        setReloadVehicles(true);
    }

    const colTab = [
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date"
        },
        {
            title: "Resp. Estatus",
            dataIndex: "respStatus",
            key: "respStatus",
            width: 200
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Fecha Arrivo",
            dataIndex: "newDate",
            key: "newDate"

        }

    ];
    console.log(history);
    return <div className="history">
            <div className="history__table">
                <Table columns={colTab} dataSource={history}></Table>     
            </div>
            <div className="history__new_date">
                <AddForm 
                    idVehicle={id} 
                    newHistory={newHistory}
                    setNewHistory={setNewHistory}
                    updateHistory={updateHistory}
                    >

                </AddForm>
            </div>
            
        </div>


}

function AddForm(props){
    const {idVehicle, setNewHistory, newHistory, updateHistory} = props;
 
    return(
        <Form className="form-edit" layout="horizontal" onSubmitCapture={updateHistory} >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="Estatus:" required="true">
                        <Input
                            placeholder="Construcción"
                            onChange={e => setNewHistory({...newHistory, status: e.target.value})}
                        >  
                        </Input>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="Resp. Estatus:" required="true">
                        <Input
                            placeholder="Ferando Torres"
                            onChange={e => setNewHistory({...newHistory, respStatus: e.target.value})}
                        >  
                        </Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Nueva Fecha:" required="true">
                        <Input
                            placeholder="13/01/2022"
                            onChange={e => setNewHistory({...newHistory, newDate: e.target.value})}                        >  
                        </Input>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submmit" className="btn-submit"> 
                    Guardar
                </Button>
            </Form.Item>
        </Form>);
}
