import logo from "../../../assets/img/logo-vetsa-blanco.png"
import {Button} from "antd";
import {MenuFoldOutlined, PoweroffOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import './MenuTop.scss';
import { logout } from "../../../api/auth";


export default function MenuTop(props){
    const {menuCollapsed, setMenuCollapsed} = props

    const logoutUser= ()=>{
        logout();
        window.location.reload();
    }

    return (
        <div className="menu-top">
            <div className="menu_top__left">
                <img 
                    className="menu-top__left-logo"
                    src={logo}
                    alt="Francisco López"
                />
                <Button 
                    type="link" 
                    onClick={() => setMenuCollapsed(!menuCollapsed)}>
                        {
                        menuCollapsed?
                        <MenuUnfoldOutlined />:
                        <MenuFoldOutlined />
                        }
                </Button>
            </div>
            <div className="menu-top__right">
                <Button
                    type="link"
                    className="off"
                    onClick={logoutUser}>
                        <PoweroffOutlined />
                  
                </Button>
            </div>
        </div>
    )
}