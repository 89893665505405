import { useEffect, useState } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getVehiclesApi } from "../../../api/vehicles";
import ListVehicles from "../../../components/Vehicles/ListVehicles";
import "./Vehicles.scss";

export default function Vehicles() {

    const [vehicles, setVehicles] = useState([]);
    const [reloadVehicles, setReloadVehicles] = useState(false);

    const token = getAccessTokenApi();

    useEffect(() => {
        getVehiclesApi(token, {}).then(res => {
            setVehicles(res.vehicles);
        });
        setReloadVehicles(false);
    }, [token, reloadVehicles]);

    return (
        <div className="Orders">
            <ListVehicles vehicles={vehicles} setReloadVehicles={setReloadVehicles}></ListVehicles>
        </div>
    );
}