import {useState} from "react";
import { Layout } from "antd";
import useAuth from "../hooks/useAuth";
import MenuTop from "../components/Admin/MenuTop/MenuTop";
import MenuSider from "../components/Admin/MenuSider/MenuSider";
import AdminSignIn from "../pages/Admin/SignIn"

import "./LayoutAdmin.scss"

export default function LayoutAdmin(props){

    const {children} = props;
    const [menuCollapsed, setMenuCollapsed] = useState(false)
    const {Header, Content, Footer} = Layout;
    
    const{user, isLoading} = useAuth();

    if (!user && !isLoading){
       return <AdminSignIn></AdminSignIn>
    }
    
    if(user && !isLoading){
        return (
            <Layout>
                <MenuSider menuCollapsed={menuCollapsed}></MenuSider>
                <Layout className="layout-admin" style={{marginLeft: menuCollapsed? "80px" : "200px"}}>
                    <Header className="layout-admin__header">
                        <MenuTop
                            menuCollapsed={menuCollapsed}
                            setMenuCollapsed={setMenuCollapsed}
                        ></MenuTop>
                    </Header>
                    <Content className="layout-admin__content">
                        {children}
                    </Content>
                    <Footer className="layout-admin__footer">Francisco López A</Footer>
                </Layout>
            </Layout>
        );
    }
    return null;
    
}
