import {useState} from "react";
import {Form, Input, Button, notification} from "antd"
import {UserOutlined,LockOutlined} from "@ant-design/icons"
import {signInApi} from "../../../api/user"
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../../../utils/constants"

import "./LoginForm.scss"

export default function LoginForm(){

    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    })

    const changeForm =(e) => {
       setInputs({
           ...inputs,
           [e.target.name]: e.target.value 
       })
    }

    const login = async () => {
        const result = await signInApi(inputs);
       

        if(result.message){
            notification["error"]({
                message: result.message,
            })
        }else{
            const {accessToken, refreshToken} = result;
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);




            notification["success"]({
                message: "Login correcto",
            });

            //return <Navigate to="/admin" />
            window.location.href = "#/admin";
            window.location.reload();
            

            //navigate('/admin', {replace : true});
            console.log("despues");
        }

    }


    return (
        <Form className="login-form" onChange={changeForm} onSubmitCapture={login}>
            <Form.Item>
                <Input 
                    prefix={<UserOutlined style={{color:"rgba(0,0,0,.25)"}}/>}
                    type="email"
                    name="email"
                    placeholder="Correo Electrónico"
                    className="login-form__input"
                   // onChange={inputValidation}
                    //value={input.email}
                    />
            </Form.Item>
            <Form.Item>
                <Input 
                    prefix={<LockOutlined  style={{color:"rgba(0,0,0,.25)"}}/>}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    className="login-form__input"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" className="login-form__button" >
                    Ingresar
                </Button>
            </Form.Item>
        </Form>
    );
}