import {useState} from "react"
import {Form, Input, Button, Checkbox, notification} from "antd"  
import {UserOutlined, LockOutlined, } from "@ant-design/icons"
import {emailValidation, minLengthValidation} from "../../../utils/formValidation" 
import {signUpApi} from "../../../api/user";

import "./RegisterForm.scss";


export default function RegisterForm(){

    const [input, setInput] = useState({
        email: "",
        password: "",
        repeatPassword: "",
        privacyPolicy: false
    });
    const [formValid, setFormValid] = useState({
        email: false,
        password: false,
        repeatPassword: false, 
        privacyPolicy: false
    });

    const changeForm = e =>{    
        if (e.target.name === "privacyPolicy"){
            setInput({
                ...input,
                [e.target.name] : e.target.checked
            })
        }else{
            setInput({
                ...input,
                [e.target.name]: e.target.value
            })
        }
    }

    const inputValidation = e =>{
        const {type, name} = e.target;
        if(type === "email"){
            setFormValid({
                    ...formValid,
                    [name]: emailValidation(e.target)
            })
        }

        if(type === "password"){
            setFormValid({
                ...formValid,
                [name]: minLengthValidation(e.target, 6)
            })
        }

        if(type === "checkbox"){
            setFormValid({
                ...formValid,
                [name]: e.target.checked
            })
        }
    }

    const register = async () =>{
        const {email, password, repeatPassword, privacyPolicy} = formValid;

        if(!email || !password || !repeatPassword){
            notification["error"]({
                message: "Los campos son obligatorios"
            })
        } else if(!privacyPolicy){
            notification["error"]({
                message: "Es obligatorio aceptar la política de privacidad"
            })
        } else{
            const result = await signUpApi(input);
            console.log(result);
            if(!result.ok){
                notification["error"]({
                    message: result.message
                });
            }else{
                notification["success"]({
                    message: result.message
                });
                resetForm();
            }

        }
    }

    const resetForm = () => {

        const inputs = document.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i ++){
            inputs[i].classList.remove("error");
            inputs[i].classList.remove("success");
        }

        setInput({
            email: "",
            password: "",
            repeatPassword: "",
            privacyPolicy: false
        });
        setFormValid({
            email: false,
            password: false,
            repeatPassword: false, 
            privacyPolicy: false
        })
    }

    return (
        <Form className="register-form" onChange={changeForm}
            onSubmitCapture={register}>
            <Form.Item> 
                <Input 
                    prefix={<UserOutlined style={{color:"rgba(0,0,0,.25)"}}/>}
                    type="email"
                    name="email"
                    placeholder="Correo Electrónico"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={input.email}
                    />
            </Form.Item>
            <Form.Item>
                <Input 
                    prefix={<LockOutlined  style={{color:"rgba(0,0,0,.25)"}}/>}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={input.password}/>
            </Form.Item>
            <Form.Item>
                <Input 
                    prefix={<LockOutlined  style={{color:"rgba(0,0,0,.25)"}}/>}
                    type="password"
                    name="repeatPassword"
                    placeholder="Confirmar Contraseña"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={input.repeatPassword}/>
            </Form.Item>
            <Form.Item>
                <Checkbox name="privacyPolicy"
                    onChange={inputValidation}
                    checked={input.privacyPolicy}
                >
                    He leído y acepto la política de privacidad.
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" className="register-form__button" >
                    Crear Cuenta
                </Button>
            </Form.Item>
        </Form>

    );
}